import { Navigate, Route, Routes } from 'react-router-dom';
import { MainPage } from '../main-page';
import { ResetPasswordPage } from '../../reset-password/reset-password-page';
import { Routing } from './routing';
import { SetPasswordPage } from '../../set-password/set-password-page';
import { UserManagementPage } from '../../user-management';
import { Documents } from '../views/data-gathering-view/documents';

export const RoutedContent = (): React.ReactElement | null => {
    return (
        <Routes>
            <Route path={Routing.main} element={<MainPage />} />
            <Route path={Routing.resetPassword} element={<ResetPasswordPage />} />
            <Route path={Routing.setPassword} element={<SetPasswordPage />} />
            <Route path={Routing.userManagement} element={<UserManagementPage />} />

            <Route path={Documents.agb} />
            <Route path={Documents.cancellationPolicy} />
            <Route path={Documents.privacyPolicy} />
            <Route path={Documents.mediationRegulation} />

            <Route path='*' element={<Navigate to={Routing.main} replace />} />
        </Routes>
    );
};
