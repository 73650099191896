import React from 'react';
import { Button, Card, Space, Typography } from 'antd';

const { Paragraph, Text, Title, Link } = Typography;

interface Props {
    processNumber: string;
    onReturnToBeginning: () => void;
}

export const SubmittedView: React.FC<Props> = ({ processNumber, onReturnToBeginning }: Props) => {
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Card className='view-card submitted-card'>
                <Space direction='vertical'>
                    <Title level={3}>{'Vielen Dank für Ihren Abschluss.'}</Title>
                    <Title level={5}>{'Ihre Vorgangsnummer ' + processNumber}</Title>
                    <Paragraph>
                        {
                            'Ihr Antrag ist bei uns eingegangen und der Versicherungsnehmer erhält in Kürze eine E-Mail mit seiner eVB-Nummber und allen relevanten Unterlagen zu seiner Versicherung.'
                        }
                    </Paragraph>
                    <Space
                        direction='vertical'
                        style={{
                            marginBottom: '24px',
                            marginTop: '24px',
                        }}
                    >
                        <Paragraph>{'Bei weiteren Fragen erreichen Sie uns per'}</Paragraph>
                        <Space direction='horizontal'>
                            <Text strong>{'E-Mail'}</Text>
                            <Link href='mailto:bvsv@sgia.de'>bvsv@sgia.de</Link>
                        </Space>
                        <Text>{'oder'}</Text>
                        <Space direction='horizontal'>
                            <Text strong>{'Telefon'}</Text>
                            <Text>{'+49 941 595648-270 von 08:00 Uhr - 18:00 Uhr'}</Text>
                        </Space>
                        <Text italic>{'Geben Sie hier bitte kurz Ihre Vorgangsnummer an.'}</Text>
                    </Space>
                    <Text>{'Beste Grüße'}</Text>
                    <Text strong>{'Ihr BVSV & SG IFFOXX-Team!'}</Text>
                    <Button
                        type='primary'
                        onClick={onReturnToBeginning}
                        style={{
                            marginTop: '24px',
                        }}
                    >
                        {'Neue Fahrzeuge versichern ⟶'}
                    </Button>
                </Space>
            </Card>
        </div>
    );
};
