import { Row, Typography } from 'antd';
import { appVersion } from '../environment';
import './app-footer.scss';

const { Link, Text } = Typography;

export const AppFooter: React.FC = () => {
    return (
        <Row justify='center'>
            <Link className='footer-link' target='blank' href={`https://www.sgia.de/impressum.php`}>
                {'Impressum'}
            </Link>
            <Link
                className='footer-link'
                target='blank'
                href={`https://www.sgia.de/datenschutz.php`}
            >
                {'Datenschutzerklärung'}
            </Link>
            <Text className='footer-text'>{'© 2024 by ZEUSS Plattform'}</Text>
            <Text className='footer-text'>{'Version ' + appVersion}</Text>
        </Row>
    );
};
