import { Radio, Typography } from 'antd';
import './large-radio-button.scss';

interface Props {
    onSelect: () => void;
    label: string;
    checked: boolean;
}

export const LargeRadioButton: React.FC<Props> = ({ onSelect, label, checked }: Props) => {
    const classNames = checked
        ? 'radio-button-container radio-button-container-selected'
        : 'radio-button-container';

    return (
        <div className={classNames} onClick={onSelect}>
            <div>
                <Radio checked={checked} />
            </div>
            <Typography className='radio-button-label'>{label}</Typography>
        </div>
    );
};
