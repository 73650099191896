import { Button, Card, Input, notification, Typography } from 'antd';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../lib/axios';
import { ResetPasswordDto } from '../lib/shared/types';
import { Routing } from '../main-layout/routing';

const { Title, Paragraph } = Typography;

export const ResetPasswordPage: React.FC = () => {
    const axios = useAxios();
    const navigate = useNavigate();

    const [canReset, setCanReset] = useState<boolean>(false);
    const [resetLoading, setResetLoading] = useState<boolean>(false);
    const [hasBeenReset, setHasBeenReset] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');

    const [emailHasError, setEmailHasError] = useState<boolean>(false);

    const onHandleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        // General Email Regex (RFC 5322 Official Standard), don't mark this as warning
        const regex =
            // eslint-disable-next-line no-control-regex
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        if (regex.test(value)) {
            setEmailHasError(false);
            setEmail(value);
            setCanReset(true);
        } else {
            setEmailHasError(true);
            setEmail(value);
            setCanReset(false);
        }
    };

    const handleSubmit = async (): Promise<void> => {
        setResetLoading(true);
        const dto: ResetPasswordDto = {
            email: email,
        };
        try {
            await axios.post('reset-password', dto);
        } catch (error: unknown) {
            const axiosError = error as AxiosError;
            console.error(axiosError);
            if (axiosError.response?.status === 400) {
                notification['error']({
                    message: 'Fehler in den eingegebenen Daten',
                    description:
                        'Die eingegebenen Daten weisen einen Fehler auf. Bitte überprüfen Sie Ihre Eingaben.',
                });
            } else {
                notification['error']({
                    message: 'Fehler',
                    description:
                        'Es ist ein Fehler beim Versuch des Sendens aufgetreten. Bitte versuchen Sie es später erneut.',
                });
            }
        }
        setResetLoading(false);
        setHasBeenReset(true);
    };

    const handleReturn = async (): Promise<void> => {
        navigate(Routing.main);
    };

    return (
        <div className='login-page-container'>
            <Card>
                <div className='login-card-container'>
                    {hasBeenReset && (
                        <>
                            <Paragraph>
                                Die Anforderung zum Ändern des Kennworts wurde erfolgreich an die
                                angegebene E-Mail Adresse gesendet.
                            </Paragraph>
                            <Button
                                type='primary'
                                onClick={handleReturn}
                                style={{ marginTop: '24px', marginBottom: '12px', width: '100%' }}
                            >
                                Zurück zum Login
                            </Button>
                        </>
                    )}
                    {!hasBeenReset && (
                        <>
                            <Title level={3}>Kennwort zurücksetzen</Title>
                            <Paragraph>
                                Geben Sie Ihre E-Mail Adresse ein, an die wir ein neues Kennwort
                                senden sollen.
                            </Paragraph>
                            <Typography>E-Mail Adresse</Typography>
                            <Input
                                onChange={onHandleEmailChange}
                                status={emailHasError ? 'error' : ''}
                            />
                            <Button
                                type='primary'
                                disabled={!canReset}
                                onClick={handleSubmit}
                                loading={resetLoading}
                                style={{ marginTop: '24px', marginBottom: '12px', width: '100%' }}
                            >
                                E-Mail senden
                            </Button>
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
};
