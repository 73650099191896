import React, { Fragment, useCallback, useEffect } from 'react';
import { useAxios } from '../../../lib/axios';
import { PasswordStrengthRequestDto, PasswordStrengthResponseDto } from '../../../lib/shared/types';
import { PasswordStrengthBarElement } from './password-strength-bar-element';
import './password-strength-bar.scss';

const barColors = ['#ddd', '#EF4836', '#F68A4D', '#F6E54D', '#8DEF2B', '#089D5F'];

interface Props {
    password: string;
    onStrong?: (isStrong: boolean) => void;
}

export const PasswordStrengthBar: React.FC<Props> = ({ password, onStrong }: Props) => {
    const axios = useAxios();

    const [score, setScore] = React.useState<number>(1);

    const onPasswordUpdated = useCallback(async (): Promise<void> => {
        const dto: PasswordStrengthRequestDto = {
            password: password,
        };
        try {
            const response = await axios.post<PasswordStrengthResponseDto>(
                'password-strength',
                dto
            );

            setScore(response.data.score + 1);

            if (onStrong) {
                onStrong(response.data.isStrong);
            }
        } catch {
            setScore(1);
            if (onStrong) {
                onStrong(false);
            }
        }
    }, [axios, password, onStrong]);

    useEffect(() => {
        onPasswordUpdated();
    }, [password, onPasswordUpdated]);

    return (
        <div className='password-strength-bar-container'>
            <div className='password-strength-bar-elements'>
                {[1, 2, 3, 4, 5].map((element: number) => {
                    return (
                        <Fragment key={`password-strength-bar-element-${element}`}>
                            <PasswordStrengthBarElement
                                score={score}
                                position={element}
                                colors={barColors}
                            />
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};
