import React from 'react';
import { Button, Card, Form, Input, notification, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Routing } from '../main-layout/routing/routing';
import { useAxios } from '../lib/axios';
import { useLogin } from '../lib/login';
import { RuleObject } from 'antd/lib/form';
import './set-password-page.scss';
import { PasswordStrengthBar } from './components/password-strength-bar';
import { useQuery } from '../lib/shared/hooks';
import { SetPasswordDto } from '../lib/shared/types';
import { AxiosError } from 'axios';

const { Item } = Form;
const { Password } = Input;
const { Paragraph } = Typography;

interface FormRules {
    oldPassword?: string;
    password: string;
    passwordRepeat: string;
}

export const SetPasswordPage: React.FC = () => {
    const axios = useAxios();
    const navigate = useNavigate();
    const { user } = useLogin();

    const token = useQuery().get('token');

    const [loading, setLoading] = React.useState(false);
    const [hasBeenSet, setHasBeenSet] = React.useState(false);

    const [form] = Form.useForm<FormRules>();

    const passwordValue = Form.useWatch('password', form);

    const onFinish = async (values: FormRules): Promise<void> => {
        setLoading(true);
        try {
            await form.validateFields();
        } catch {
            notification['error']({
                message: 'Ein Fehler ist aufgetreten',
                description: 'Ein oder mehrere Werte im Formular können nicht verarbeitet werden.',
            });
            setLoading(false);
            return;
        }

        const dto: SetPasswordDto = {
            token: token,
            password: values.password,
            oldPassword: values.oldPassword,
        };
        try {
            await axios.post('confirm-reset-password', dto);
            setHasBeenSet(true);
        } catch (error: unknown) {
            const axiosError = error as AxiosError;
            const data = axiosError.response?.data as {
                errors: { oldPassword?: string; passwordStrength?: string };
            };
            if (data?.errors?.oldPassword) {
                notification['error']({
                    message: 'Ein Fehler ist aufgetreten',
                    description: 'Das alte Kennwort ist inkorrekt.',
                });
            } else if (data?.errors?.passwordStrength) {
                notification['error']({
                    message: 'Ein Fehler ist aufgetreten',
                    description: 'Das neue Kennwort ist zu unsicher',
                });
            } else {
                notification['error']({
                    message: 'Ein Fehler ist aufgetreten',
                    description: 'Das Kennwort konnte nicht gesetzt werden.',
                });
            }
        }

        setLoading(false);
    };
    const handleReturnToMain = async (): Promise<void> => {
        navigate(Routing.main);
    };

    if (!user && !token) {
        return null;
    }

    return (
        <div className='set-password-container'>
            <Card>
                <div className='set-password-card-container'>
                    {hasBeenSet ? (
                        <>
                            <Paragraph>Das Kennwort wurde erfolgreich gesetzt.</Paragraph>
                            <Button
                                type='primary'
                                onClick={handleReturnToMain}
                                style={{ marginTop: '24px', marginBottom: '12px', width: '100%' }}
                            >
                                {user ? 'Zurück zur Startseite' : 'Zurück zum Login'}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography.Title className='set-password-title' level={4}>
                                Kennwort setzen
                            </Typography.Title>

                            <Form
                                form={form}
                                requiredMark={false}
                                layout='vertical'
                                onFinish={onFinish}
                            >
                                {token == null && (
                                    <Item
                                        name='oldPassword'
                                        label='Altes Kennwort'
                                        rules={[
                                            {
                                                type: 'string',
                                                required: true,
                                                message: 'Bitte ihr altes Kennwort eingeben',
                                            },
                                        ]}
                                    >
                                        <Password />
                                    </Item>
                                )}
                                <Item
                                    name='password'
                                    label='Kennwort'
                                    rules={[
                                        {
                                            type: 'string',
                                            required: true,
                                            message: 'Bitte ihr neues Kennwort eingeben',
                                        },
                                    ]}
                                >
                                    <Password />
                                </Item>
                                <PasswordStrengthBar password={passwordValue} />
                                <Item
                                    dependencies={['password']}
                                    name='passwordRepeat'
                                    label='Kennwort wiederholen'
                                    rules={[
                                        {
                                            type: 'string',
                                            required: true,
                                            message: 'Bitte ihr neues Kennwort wiederholen',
                                        },
                                        ({ getFieldValue }): RuleObject => ({
                                            validator(_, value: string): Promise<void> {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        'Die beiden Kennwörter stimmen nicht überein'
                                                    )
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Password />
                                </Item>
                                <Item>
                                    <Button
                                        type='primary'
                                        onClick={(): void => form.submit()}
                                        loading={loading}
                                        style={{ marginTop: '12px', width: '100%' }}
                                    >
                                        Kennwort setzen
                                    </Button>
                                </Item>
                            </Form>
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
};
