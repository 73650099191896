import { Image, Typography } from 'antd';
import { Routing } from './routing';
import './app-header.scss';
import { isBrowser, isMobile } from 'react-device-detect';
import { UserMenu } from './components';

const { Link } = Typography;

export const AppHeader: React.FC = () => {
    return (
        <div className='header-container'>
            <div className='header-corner'>
                <Link href={Routing.main}>
                    <Image src='/static/images/Logo.jpg' preview={false} height='64px' />
                </Link>
            </div>
            <div className='header-corner-shadow'></div>
            {isBrowser && (
                <>
                    <div className='header-image'>
                        <Image
                            src='/static/images/Logo_SG-IFFOXX.png'
                            preview={false}
                            height='48px'
                        />
                    </div>
                    <div className='header-fill'></div>
                    <div>
                        <UserMenu mobile={false} />
                    </div>
                </>
            )}
            {isMobile && (
                <>
                    <div className='header-menu'>
                        <UserMenu mobile />
                    </div>
                </>
            )}
        </div>
    );
};
