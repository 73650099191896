import { Avatar, Button, Dropdown, MenuProps } from 'antd';
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons';
import { ItemType, MenuDividerType, MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../../../lib/login';
import { Routing } from '../../routing';
import { Roles } from '../../../lib/shared/types';
import './user-menu.scss';
import { ReactNode } from 'react';
import React from 'react';
import { isBrowser } from 'react-device-detect';

const useProps = (): MenuProps => {
    const { user, logout } = useLogin();
    const navigate = useNavigate();

    const onLogout = async (): Promise<void> => {
        await logout();
        navigate(Routing.main);
    };

    const nameItem: MenuItemType = {
        key: 'name',
        label: user?.email,
        style: { pointerEvents: 'none' },
    };

    const dividerItem: MenuDividerType = {
        key: 'divider',
        type: 'divider',
    };

    const menuItems: ItemType[] = [nameItem, dividerItem];

    if (isBrowser && user && user.roles.some((value) => value === Roles.admin)) {
        menuItems.push({
            key: 'userManagement',
            label: 'Benutzerverwaltung',
            onClick: () => navigate(Routing.userManagement),
        } as MenuItemType);
    }

    menuItems.push({
        key: 'logout',
        label: 'Abmelden',
        onClick: () => onLogout(),
    } as MenuItemType);

    return {
        items: menuItems,
    };
};

const UserDropdown: React.FC<ReactNode> = (origin: ReactNode) => {
    return (
        <div>
            {React.cloneElement(origin as React.ReactElement, { className: 'user-menu-contents' })}
        </div>
    );
};

interface Props {
    mobile: boolean;
}

export const UserMenu: React.FC<Props> = ({ mobile }: Props) => {
    const menuProps = useProps();

    return (
        <>
            {mobile ? (
                <Dropdown
                    trigger={['click']}
                    menu={menuProps}
                    dropdownRender={UserDropdown}
                    placement='bottomLeft'
                >
                    <Button type='text'>
                        <Avatar className='user-menu-icon' icon={<UserOutlined />} />
                    </Button>
                </Dropdown>
            ) : (
                <Dropdown menu={menuProps} dropdownRender={UserDropdown} placement='bottomLeft'>
                    <Button type='text'>
                        <Avatar className='user-menu-icon' icon={<UserOutlined />} />
                        <CaretDownOutlined className='user-menu-arrow' />
                    </Button>
                </Dropdown>
            )}
        </>
    );
};
