import { Card, Divider, Typography } from 'antd';
import React from 'react';
import { RateDto } from '../../../../../lib/shared/types';
import { LargeRadioButton } from '../large-radio-button';
import './contract-variant-card.scss';
import { StarFilled } from '@ant-design/icons';

interface Props {
    rate: RateDto;
    isSelected: boolean;
    paymentMethod: string;
    onSelected: () => void;
    isHighlighted?: boolean;
}

export const ContractVariantCard: React.FC<Props> = ({
    rate,
    isSelected,
    paymentMethod,
    onSelected,
    isHighlighted,
}: Props) => {
    const cardClasses = isSelected
        ? 'view-card selectable-card selected-card'
        : 'view-card selectable-card';

    return (
        <div className={isHighlighted ? 'highlighted-container' : 'not-highlighted-container'}>
            {isHighlighted && (
                <div className='highlighted-title'>
                    <StarFilled /> <StarFilled className='fontSize2rem' /> <StarFilled />
                    <div>Unsere Empfehlung</div>
                </div>
            )}
            <Card
                bodyStyle={{ padding: 0 }}
                className={cardClasses + (isHighlighted ? ' highlighted-card' : '')}
                onClick={onSelected}
            >
                <div className='contract-card-header'>
                    <div className='flex-centered'>
                        <LargeRadioButton
                            onSelect={onSelected}
                            label={rate.rateTitle}
                            checked={isSelected}
                        />
                    </div>
                    <Typography className='bold text-centered primary-text'>
                        {rate.rateDescription}
                    </Typography>
                </div>
                <div className='contract-card-body'>
                    <div className='contract-card-content'>
                        {rate.sbVariant === '-' ? (
                            <Typography>{'Keine Voll-/Teilkasko'}</Typography>
                        ) : (
                            <>
                                <Typography>{'Selbstbeteiligung'}</Typography>
                                <Typography className='bold'>{rate.sbVariant}</Typography>
                            </>
                        )}
                    </div>

                    <Divider className='divider' />

                    <Typography className='extrabold text-centered fontSize2rem primary-text'>
                        {rate.rateData.gross}
                    </Typography>
                    <Typography className='bold text-centered primary-text'>
                        {paymentMethod}
                    </Typography>
                    <Typography className='bold text-centered primary-text'>
                        {'inkl. Versicherungssteuer'}
                    </Typography>
                </div>
            </Card>
        </div>
    );
};
