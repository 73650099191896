import { Layout } from 'antd';
import { AppFooter, AppHeader } from '.';
import { usePathInterceptor } from '../lib/axios';
import { useAppVersionInterceptor } from '../lib/shared/hooks';
import './bvsv-app.scss';
import { RoutedContent } from './routing';

const { Header, Content, Footer } = Layout;

export const BvsvApp: React.FC = () => {
    usePathInterceptor();
    useAppVersionInterceptor();

    return (
        <Layout>
            <Header>
                <AppHeader />
            </Header>

            <Content className='app-container'>
                <RoutedContent />
            </Content>

            <Footer>
                <AppFooter />
            </Footer>
        </Layout>
    );
};
