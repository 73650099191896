import { notification } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import './app.less';
import { AxiosProvider } from './lib/axios';
import { LoginProvider } from './lib/login';
import { BvsvApp } from './main-layout';

export const App = (): React.ReactElement => {
    notification.config({ placement: 'bottomRight' });

    return (
        <AxiosProvider>
            <LoginProvider>
                <BrowserRouter>
                    <BvsvApp />
                </BrowserRouter>
            </LoginProvider>
        </AxiosProvider>
    );
};

export default App;
