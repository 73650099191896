import React from 'react';
import { Button, Card, Form, Input, notification, Typography } from 'antd';
import { useLogin } from '../../../lib/login';
import { useNavigate } from 'react-router-dom';
import { Routing } from '../../routing';
import './login-view.scss';

const { Link } = Typography;
const { Item } = Form;
const { Password } = Input;

interface FormRules {
    email: string;
    password: string;
}

export const LoginView: React.FC = () => {
    const { login } = useLogin();
    const navigate = useNavigate();

    const [loginLoading, setLoginLoading] = React.useState(false);

    const [form] = Form.useForm<FormRules>();

    const onFinish = async (data: FormRules): Promise<void> => {
        setLoginLoading(true);
        try {
            const success = await login(data.email, data.password);
            if (success) {
                navigate(Routing.main);
            } else {
                notification['error']({
                    message: 'Login nicht erfolgreich',
                    description: 'Benutzername oder Kennwort sind nicht korrekt.',
                });
            }
        } finally {
            setLoginLoading(false);
        }
    };

    return (
        <div className='login-page-container'>
            <Card className='view-card'>
                <div className='login-card-container'>
                    <Typography.Title className='login-title' level={4}>
                        Bitte loggen Sie sich ein
                    </Typography.Title>

                    <Form form={form} requiredMark={false} layout='vertical' onFinish={onFinish}>
                        <Item
                            name='email'
                            label='E-Mail Adresse'
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Keine gültige E-Mail Adresse',
                                },
                                {
                                    required: true,
                                    message: 'Bitte eine E-Mail Adresse angeben',
                                },
                            ]}
                        >
                            <Input />
                        </Item>
                        <Item
                            name='password'
                            label='Kennwort'
                            rules={[
                                {
                                    required: true,
                                    message: 'Bitte ihr Kennwort eingeben',
                                },
                            ]}
                        >
                            <Password />
                        </Item>
                        <Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={loginLoading}
                                style={{ marginTop: '12px', width: '100%' }}
                            >
                                Anmelden
                            </Button>
                        </Item>
                        <Item
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            style={{ marginBottom: 0 }}
                        >
                            <Link href={Routing.resetPassword}>Kennwort vergessen</Link>
                        </Item>
                    </Form>
                </div>
            </Card>
        </div>
    );
};
