import { Button, Card, Checkbox, Form, Input, Radio, Typography } from 'antd';
import React from 'react';
import { RateDto } from '../../../lib/shared/types';
import { normalizeIban } from '../../../lib/shared/validations';
import { Documents } from './documents';
import './data-gathering-view.scss';
import { RateViewer } from './components';
import { isBrowser } from 'react-device-detect';

const { Item } = Form;
const { Link } = Typography;

interface Props {
    rate: RateDto;
    paymentMethod: string | undefined;
    vehicleType: string | undefined;
    onFormFinish: (values: FormRules) => Promise<void>;
    onGoBack: () => void;
    ibanError?: string;
}

interface FormRules {
    company?: string;
    firstName: string;
    lastName: string;
    street: string;
    houseNumber: string;
    postalCode: string;
    place: string;
    email: string;
    telephoneNumber: string;
    iban: string;
    privacyNoticeAcceepted: boolean;
    brokerContractAccepted: boolean;
}

export const DataGathering: React.FC<Props> = ({
    rate,
    paymentMethod,
    vehicleType,
    onFormFinish,
    onGoBack,
    ibanError,
}: Props) => {
    const [form] = Form.useForm<FormRules>();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [isCompany, setIsCompany] = React.useState<boolean>(false);

    const onFinish = async (values: FormRules): Promise<void> => {
        setLoading(true);
        try {
            await form.validateFields();
            values.iban = 'DE' + values.iban;
            await onFormFinish(values);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='data-gathering-card-container'>
            <Card className='view-card'>
                <Form form={form} requiredMark={false} layout='vertical' onFinish={onFinish}>
                    <div className='data-gathering-form'>
                        <div className='data-gathering-form-column'>
                            <Typography className='data-gathering-form-column-header'>
                                {'Persönliche Angaben - Versicherungsnehmer'}
                            </Typography>
                            <div className='data-gathering-form-pushdown data-gathering-form-bottom-space'>
                                <Radio
                                    checked={!isCompany}
                                    onClick={(): void => setIsCompany(false)}
                                >
                                    <Typography>{'privat'}</Typography>
                                </Radio>
                                <Radio checked={isCompany} onClick={(): void => setIsCompany(true)}>
                                    <Typography>{'gewerblich'}</Typography>
                                </Radio>
                            </div>

                            {isCompany && (
                                <Item
                                    name='company'
                                    label='Firma'
                                    rules={[
                                        {
                                            type: 'string',
                                            required: true,
                                            message: 'Bitte Firma eintragen',
                                        },
                                    ]}
                                    className='data-gathering-form-left-fullwidth'
                                >
                                    <Input maxLength={200} />
                                </Item>
                            )}
                            {!isCompany && isBrowser && (
                                <div className='data-gathering-form-left-halfwidth'></div>
                            )}

                            <Item
                                name='firstName'
                                label='Vorname'
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: 'Bitte Vornamen eintragen',
                                    },
                                ]}
                                className='data-gathering-form-left-halfwidth'
                            >
                                <Input maxLength={40} />
                            </Item>
                            <Item
                                name='lastName'
                                label='Nachname'
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: 'Bitte Nachnamen eintragen',
                                    },
                                ]}
                                className='data-gathering-form-left-halfwidth2'
                            >
                                <Input maxLength={40} />
                            </Item>
                            <Item
                                name='street'
                                label='Straße'
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: 'Bitte Straße eintragen',
                                    },
                                ]}
                                className='data-gathering-form-left-halfwidth'
                            >
                                <Input maxLength={44} />
                            </Item>
                            <Item
                                name='houseNumber'
                                label='Hausnummer'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte Hausnummer eintragen',
                                    },
                                ]}
                                className='data-gathering-form-left-halfwidth2'
                            >
                                <Input maxLength={5} />
                            </Item>
                            <Item
                                name='postalCode'
                                label='Postleitzahl'
                                normalize={(input: string): string => input.replace(/\D/g, '')}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte eine Postleitzahl eintragen',
                                    },
                                    {
                                        pattern: /^[\d]{5}$/,
                                        message: 'Ungültige Postleitzahl',
                                    },
                                ]}
                                className='data-gathering-form-left-halfwidth'
                            >
                                <Input maxLength={5} />
                            </Item>
                            <Item
                                name='place'
                                label='Ort'
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: 'Bitte Ort eintragen',
                                    },
                                ]}
                                className='data-gathering-form-left-halfwidth2'
                            >
                                <Input maxLength={50} />
                            </Item>
                            <Item
                                name='email'
                                label='E-Mail'
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                        message: 'Bitte eine gültige E-Mail Adresse eintragen',
                                    },
                                ]}
                                className='data-gathering-form-left-halfwidth'
                            >
                                <Input maxLength={80} />
                            </Item>
                            <Item
                                name='telephoneNumber'
                                label='Telefon'
                                rules={[
                                    {
                                        type: 'string',
                                        required: true,
                                        message: 'Bitte Telefonnummer eintragen',
                                    },
                                ]}
                                className='data-gathering-form-left-halfwidth2'
                            >
                                <Input maxLength={35} />
                            </Item>
                            <Typography className='data-gathering-form-column-header data-gathering-form-left-pushdown'>
                                {'Zahlung '}
                                <b>{'S€PA'}</b>
                            </Typography>

                            <Item
                                name='iban'
                                label='IBAN'
                                normalize={(value: string): string => normalizeIban(value, true)}
                                className='data-gathering-form-left-fullwidth'
                                validateStatus={ibanError ? 'error' : undefined}
                                help={ibanError}
                            >
                                <Input
                                    addonBefore={
                                        <Item noStyle>
                                            <div>DE</div>
                                        </Item>
                                    }
                                />
                            </Item>
                        </div>
                        <div className='data-gathering-form-column data-gathering-form-pushdown'>
                            <Typography className='data-gathering-form-column-header'>
                                {'Versicherungsprämie'}
                            </Typography>
                            <RateViewer
                                className='data-gathering-form-right-halfheight'
                                rate={rate}
                                paymentMethod={paymentMethod}
                                vehicleType={vehicleType}
                            />
                            <Item
                                name='privacyNoticeAcceepted'
                                valuePropName='checked'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte die Datenschutzhinweise akzeptieren',
                                        validator: (_, value) =>
                                            value === true ? Promise.resolve() : Promise.reject(),
                                    },
                                ]}
                                className='data-gathering-form-right-fullwidth'
                            >
                                <Checkbox style={{ marginTop: '24px' }}>
                                    {'Hiermit bestätige ich, dass ich die '}
                                    <Link href={Documents.agb} target='_blank'>
                                        {'AGB'}
                                    </Link>
                                    {', '}
                                    <Link href={Documents.cancellationPolicy} target='_blank'>
                                        {'Widerrufsbelehrung'}
                                    </Link>
                                    {', '}
                                    <Link href={Documents.privacyPolicy} target='_blank'>
                                        {'Datenschutzhinweise'}
                                    </Link>
                                    {' und '}
                                    <Link href={Documents.mediationRegulation} target='_blank'>
                                        {
                                            'Erstinformationen gemäß § 15 Versicherungsvermittlungsverordnung '
                                        }
                                    </Link>
                                    {'zur Kenntnis genommen habe.'}
                                </Checkbox>
                            </Item>
                            <Item
                                name='brokerContractAccepted'
                                valuePropName='checked'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte den Maklerauftrag erteilen',
                                        validator: (_, value) =>
                                            value === true ? Promise.resolve() : Promise.reject(),
                                    },
                                ]}
                                className='data-gathering-form-right-fullwidth'
                            >
                                <Checkbox>
                                    {
                                        'Hiermit erteile ich der SG IFFOXX Assekuranzmaklergesellschaft mbH mit sofortiger Wirkung den Maklerauftrag für die Verwaltung der Kfz-Versicherungsverträge, die über die BVSV Plattform abgeschlossen werden und verzichte auf weitere Beratung und Dokumentationen.'
                                    }
                                </Checkbox>
                            </Item>
                        </div>
                    </div>
                    {isBrowser ? (
                        <div style={{ display: 'flex' }}>
                            <Button
                                type='link'
                                loading={loading}
                                onClick={onGoBack}
                                style={{
                                    alignSelf: 'left',
                                    marginTop: '12px',
                                }}
                            >
                                {'⟵ Zurück'}
                            </Button>
                            <div style={{ flex: '1 0 0' }} />
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={loading}
                                style={{
                                    alignSelf: 'right',
                                    marginTop: '12px',
                                }}
                            >
                                {'Zahlungspflichtig abschließen ⟶'}
                            </Button>
                        </div>
                    ) : (
                        <>
                            <Button
                                type='link'
                                loading={loading}
                                onClick={onGoBack}
                                style={{
                                    alignSelf: 'left',
                                    marginTop: '12px',
                                }}
                            >
                                {'⟵ Zurück'}
                            </Button>
                            <Button
                                type='primary'
                                htmlType='submit'
                                loading={loading}
                                style={{
                                    alignSelf: 'right',
                                    marginTop: '12px',
                                }}
                            >
                                {'Zahlungspflichtig abschließen ⟶'}
                            </Button>
                        </>
                    )}
                </Form>
            </Card>
        </div>
    );
};
