import { Button, Card, Col, Form, notification, Row, Typography } from 'antd';
import Select, { DefaultOptionType } from 'antd/lib/select';
import React from 'react';
import { isBrowser } from 'react-device-detect';
import { RateDto } from '../../../lib/shared/types';
import { ContractVariantCard } from './components/contract-variant-card/contract-variant-card';
import './contract-choice-view.scss';
import { SelectedCategories } from '../../main-page';

interface Props {
    onPriceCategoryChange: (value: string) => void;
    onPowerCategoryChange: (value: string) => void;
    onPaymentMethodChange: (value: string) => void;
    selectedRateId: string | undefined;
    onSelectedRateIdChange: (value: string) => void;
    onRateChange: (rate: RateDto) => void;
    onVehicleTypeChange: (vehicleType: string) => void;
    rates: RateDto[];
    paymentMethods: DefaultOptionType[];
    vehicleTypes: DefaultOptionType[];
    powerCategories: DefaultOptionType[];
    priceCategories: DefaultOptionType[];
    selectedCategories: SelectedCategories;
}

export const ContractChoiceView: React.FC<Props> = ({
    onPriceCategoryChange,
    onPowerCategoryChange,
    onPaymentMethodChange,
    onRateChange,
    onVehicleTypeChange,
    paymentMethods,
    vehicleTypes,
    powerCategories,
    priceCategories,
    rates,
    selectedCategories,
    selectedRateId,
    onSelectedRateIdChange,
}: Props) => {
    const [loading, setLoading] = React.useState<boolean>(false);

    const selectedPaymentMethod = paymentMethods.find(
        (item) => item.value === selectedCategories.paymentMethod
    );
    const selectedVehicleType = vehicleTypes.find(
        (item) => item.value === selectedCategories.vehicleType
    );
    const selectedPriceCategory = priceCategories.find(
        (item) => item.value === selectedCategories.priceCategory
    );
    const selectedPowerCategory = powerCategories.find(
        (item) => item.value === selectedCategories.powerCategory
    );

    const onPaymentMethodApply = (value: string | number): void => {
        if (typeof value === 'string') {
            onPaymentMethodChange(value);
        }
    };

    const onVehicleTypeApply = (value: string | number): void => {
        if (typeof value === 'string') {
            onVehicleTypeChange(value);
        }
    };

    const onPriceCategoryApply = (value: string | number): void => {
        if (typeof value === 'string') {
            onPriceCategoryChange(value);
        }
    };

    const onPowerCategoryApply = (value: string | number): void => {
        if (typeof value === 'string') {
            onPowerCategoryChange(value);
        }
    };

    const onSubmit = (): void => {
        setLoading(true);
        const rate = rates.find((item) => item.rateId === selectedRateId);
        if (!rate) {
            notification['error']({
                message: 'Fehler',
                description:
                    'Es ist ein Fehler beim Versuch des Sendens aufgetreten. Bitte versuchen Sie es später erneut.',
            });
        } else {
            onRateChange(rate);
        }
        setLoading(false);
    };

    const columnSize = isBrowser ? Math.floor(60 / rates.length) : 60;

    return (
        <div className='contract-choice-container'>
            <Card className='view-card contract-choice-upper-container'>
                <Form layout='vertical'>
                    <div className='contract-choice-form-content'>
                        <Form.Item className='contract-choice-first' label='Zahlweise'>
                            <Select
                                className='contract-choice-form-select'
                                options={paymentMethods}
                                onChange={(value, _): void => onPaymentMethodApply(value)}
                                value={selectedPaymentMethod?.value}
                            />
                        </Form.Item>
                        <Form.Item className='contract-choice-second' label='Fahrzeugtyp'>
                            <Select
                                className='contract-choice-form-select'
                                options={vehicleTypes}
                                onChange={(value, _): void => onVehicleTypeApply(value)}
                                value={selectedVehicleType?.value}
                            />
                        </Form.Item>
                    </div>
                </Form>

                {selectedCategories.paymentMethod && selectedCategories.vehicleType && (
                    <Form layout='vertical'>
                        <div className='contract-choice-form-content'>
                            <Form.Item className='contract-choice-first' label='Bruttolistenpreis'>
                                <Select
                                    className='contract-choice-form-select'
                                    options={priceCategories}
                                    onChange={(value, _): void => onPriceCategoryApply(value)}
                                    value={selectedPriceCategory?.value}
                                />
                            </Form.Item>
                            <Form.Item className='contract-choice-second' label='Leistung in KW'>
                                <Select
                                    className='contract-choice-form-select'
                                    options={powerCategories}
                                    onChange={(value, _): void => onPowerCategoryApply(value)}
                                    value={selectedPowerCategory?.value}
                                />
                            </Form.Item>
                        </div>
                    </Form>
                )}
            </Card>
            {selectedCategories.paymentMethod &&
                selectedCategories.vehicleType &&
                selectedCategories.priceCategory &&
                selectedCategories.powerCategory && (
                    <Row className='flex-container' gutter={[24, 24]}>
                        {rates.map((rate, i) => (
                            <Col key={rate.rateId} span={columnSize}>
                                <ContractVariantCard
                                    rate={rate}
                                    isSelected={selectedRateId === rate.rateId}
                                    paymentMethod={selectedCategories.paymentMethod!}
                                    onSelected={(): void => onSelectedRateIdChange(rate.rateId)}
                                    isHighlighted={i === 1}
                                />
                            </Col>
                        ))}
                    </Row>
                )}
            <Card className='view-card passive-card'>
                <Typography className='text-centered'>
                    {
                        'Die elektronische Versicherungsbestätigungs-Nummer (eVB) erhält der Versicherungsnehmer per E-Mail nach dem Online-Abschluss.'
                    }
                </Typography>
            </Card>
            <div>
                <Button
                    type='primary'
                    loading={loading}
                    onClick={onSubmit}
                    disabled={
                        !selectedCategories.paymentMethod ||
                        !selectedCategories.vehicleType ||
                        !selectedRateId
                    }
                    style={{ marginTop: '12px', width: '100%' }}
                >
                    {'Übernehmen'}
                </Button>
            </div>
        </div>
    );
};
