export const normalizeIban = (iban: string, removeDePrefix: boolean): string => {
    const loweredIban = iban.toLowerCase();
    if (removeDePrefix && loweredIban.startsWith('de')) {
        iban = iban.slice(2);
    }

    iban = iban.replace(' ', '').replace('-', '');

    if (iban.length > 20) {
        iban = iban.slice(0, 20);
    }

    return iban;
};
