import { CloseOutlined, DeleteOutlined, MailOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, notification, Row, Select, Tooltip, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import React, { useEffect } from 'react';
import { useAxios } from '../../lib/axios';
import { UserDto } from '../../lib/shared/types';
import { Roles } from '../../lib/shared/types/roles';
import './user-edit-form.scss';

const { Item } = Form;
const { Title } = Typography;

const roles: DefaultOptionType[] = [
    {
        label: 'Administrator',
        value: Roles.admin,
    },
    {
        label: 'Verkäufer',
        value: Roles.user,
    },
];

interface FormRules {
    email: string;
    firstName: string;
    lastName: string;
    roles: readonly string[];
}

interface UserEditFormPops {
    selectedUser: UserDto | null;
    onSaveUser: (value: UserDto) => Promise<void>;
    onDeleteUser: () => void;
}

export const UserEditForm: React.FC<UserEditFormPops> = ({
    selectedUser,
    onSaveUser,
    onDeleteUser,
}: UserEditFormPops) => {
    const axios = useAxios();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [form] = Form.useForm<FormRules>();

    const onFinish = async (values: FormRules): Promise<void> => {
        setLoading(true);
        try {
            await form.validateFields();
            onSaveUser({ id: selectedUser?.id ?? '', ...values });
        } finally {
            setLoading(false);
        }
    };

    const onRevertChanges = (): void => {
        form.resetFields();
    };

    const resendEmail = async (): Promise<void> => {
        if (!selectedUser) {
            notification['error']({
                message: 'E-Mail konnte nicht versendet werden',
                description: 'Es ist kein Benutzer ausgewählt.',
            });
            return;
        }

        const response = await axios.post('resend-registration', selectedUser, {
            validateStatus: () => true,
        });
        if (response.status === 200) {
            notification['success']({
                message: 'Senden erfolgreich',
                description: 'Eine E-Mail zum Setzen des Kennwortes wurde erfolgreich versendet.',
            });
        } else if (response.status === 400) {
            notification['error']({
                message: 'E-Mail konnte nicht versendet werden',
                description: 'Der Benutzer konnte in der Datenbank nicht gefunden werden.',
            });
        } else {
            notification['error']({
                message: 'E-Mail konnte nicht versendet werden',
                description:
                    'Die E-Mail konnte nicht an die angegebene E-Mail Adresse versendet werden.',
            });
        }
    };

    useEffect(() => {
        form.setFieldValue('firstName', selectedUser?.firstName);
        form.setFieldsValue({
            firstName: selectedUser?.firstName,
            lastName: selectedUser?.lastName,
            email: selectedUser?.email,
            roles: selectedUser?.roles,
        });
    }, [form, selectedUser]);

    if (!selectedUser) {
        return null;
    }

    return (
        <Form form={form} layout='vertical' onFinish={onFinish}>
            <div className='user-edit-form-header'>
                <Title level={4}>Benutzerdetails</Title>
                <Tooltip title='Ausgewählten Benutzer löschen'>
                    <Button danger loading={loading} onClick={onDeleteUser}>
                        <DeleteOutlined />
                    </Button>
                </Tooltip>
                <Tooltip title='Änderungen verwerfen'>
                    <Button loading={loading} onClick={onRevertChanges}>
                        <CloseOutlined />
                    </Button>
                </Tooltip>
                <Tooltip title='Änderungen speichern'>
                    <Button type='primary' loading={loading} onClick={(): void => form.submit()}>
                        <SaveOutlined />
                    </Button>
                </Tooltip>
            </div>
            <Row gutter={[8, 4]}>
                <Col span={30}>
                    <Item
                        name='firstName'
                        label='Vorname'
                        rules={[
                            {
                                type: 'string',
                                required: true,
                                message: 'Bitte Vornamen eintragen',
                            },
                        ]}
                    >
                        <Input />
                    </Item>
                </Col>
                <Col span={30}>
                    <Item
                        name='lastName'
                        label='Nachname'
                        rules={[
                            {
                                type: 'string',
                                required: true,
                                message: 'Bitte Nachnamen eintragen',
                            },
                        ]}
                    >
                        <Input />
                    </Item>
                </Col>
                <Col span={30}>
                    <Item
                        name='email'
                        label='E-Mail Adresse'
                        rules={[
                            {
                                type: 'email',
                                message: 'Keine gültige E-Mail Adresse',
                            },
                            {
                                required: true,
                                message: 'Bitte eine E-Mail Adresse angeben',
                            },
                        ]}
                    >
                        <Input />
                    </Item>
                </Col>
                <Col span={30}>
                    <Tooltip title='Aktivierungsemail erneut versenden'>
                        <Button
                            loading={loading}
                            onClick={resendEmail}
                            style={{ marginTop: '31px' }}
                        >
                            <MailOutlined />
                        </Button>
                    </Tooltip>
                </Col>
                <Col span={30}>
                    <Item
                        name='roles'
                        label='Rollen'
                        rules={[
                            {
                                required: true,
                                message: 'Bitte mindestens eine Rolle eintragen',
                            },
                        ]}
                    >
                        <Select mode='multiple' options={roles} showArrow />
                    </Item>
                </Col>
            </Row>
        </Form>
    );
};
