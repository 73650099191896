import { Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import { RateDto } from '../../../../../lib/shared/types';
import './rate-viewer.scss';

interface Props {
    className: string | undefined;
    rate: RateDto;
    paymentMethod: string | undefined;
    vehicleType: string | undefined;
}

export const RateViewer: React.FC<Props> = ({
    className,
    rate,
    paymentMethod,
    vehicleType,
}: Props) => {
    const classNames = className + ' rate-viewer-container';

    return (
        <div className={classNames}>
            <Row>
                <Col span={60}>
                    <Typography className='rate-viewer-title bold primary-text'>
                        {rate.rateTitle}
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col span={60}>
                    <Typography className='bold'>{rate.rateDescription}</Typography>
                </Col>
            </Row>

            <Row className='rate-viewer-pushdown'>
                <Col span={30}>Selbstbehalt</Col>
                <Col span={30}>
                    <Typography className='bold'>{rate.sbVariant}</Typography>
                </Col>
            </Row>
            <Row>
                <Col span={30}>Zahlweise</Col>
                <Col span={30}>
                    <Typography className='bold'>{paymentMethod}</Typography>
                </Col>
            </Row>
            <Row>
                <Col span={30}>Fahrzeugart</Col>
                <Col span={30}>
                    <Typography className='bold'>{vehicleType}</Typography>
                </Col>
            </Row>

            <Row className='rate-viewer-pushdown'>
                <Col span={30}>Haftpflicht</Col>
                <Col span={30}>
                    <Typography className='rate-viewer-currency'>
                        {rate.rateData.liabilityInsurance}
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col span={30}>Vollkasko / Teilkasko</Col>
                <Col span={30}>
                    <Typography className='rate-viewer-currency'>
                        {rate.rateData.comprehensive}
                    </Typography>
                </Col>
            </Row>

            <Divider className='rate-viewer-divider' />

            <Row>
                <Col span={30}>Prämie Netto {paymentMethod}</Col>
                <Col span={30}>
                    <Typography className='rate-viewer-currency'>{rate.rateData.net}</Typography>
                </Col>
            </Row>
            <Row>
                <Col span={30}>Versicherungssteuer 19%</Col>
                <Col span={30}>
                    <Typography className='rate-viewer-currency'>{rate.rateData.tax}</Typography>
                </Col>
            </Row>

            <Divider className='rate-viewer-divider' />

            <Row>
                <Col className='bold' span={30}>
                    Prämie Brutto {paymentMethod}
                </Col>
                <Col span={30}>
                    <Typography className='rate-viewer-currency bold'>
                        {rate.rateData.gross}
                    </Typography>
                </Col>
            </Row>
        </div>
    );
};
