import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useAxios } from '../lib/axios';
import { useLogin } from '../lib/login';
import {
    CategoriesDto,
    GetRatesResponseDto,
    RateDto,
    SubmitDataRequestDto,
    SubmitDataResponseDto,
    VehicleSpecificationsDto,
} from '../lib/shared/types';
import './main-page.scss';
import { ContractChoiceView, DataGathering, LoginView, SubmittedView } from './views';

interface FormValues {
    company?: string;
    firstName: string;
    lastName: string;
    street: string;
    houseNumber: string;
    postalCode: string;
    place: string;
    email: string;
    telephoneNumber: string;
    iban: string;
    privacyNoticeAcceepted: boolean;
    brokerContractAccepted: boolean;
}

export interface SelectedCategories {
    priceCategory?: string;
    powerCategory?: string;
    paymentMethod?: string;
    vehicleType?: string;
}

export const MainPage: React.FC = () => {
    const axios = useAxios();
    const { isAutoLoginCompleted, user } = useLogin();

    const [selectedRateId, setSelectedRateId] = useState<string>();
    const [rate, setRate] = useState<RateDto | null>();
    const [categories, setCategories] = useState<SelectedCategories>({});
    const [paymentMethods, setPaymentMethods] = useState<DefaultOptionType[]>([]);
    const [vehicleTypes, setVehicleTypes] = useState<DefaultOptionType[]>([]);
    const [powerCategories, setPowerCategories] = useState<DefaultOptionType[]>([]);
    const [priceCategories, setPriceCategories] = useState<DefaultOptionType[]>([]);
    const [rates, setRates] = useState<RateDto[]>([]);
    const [processNumber, setProcessNumber] = useState<string>();
    const [ibanError, setIbanError] = useState<string>();

    const onSubmit = async (values: FormValues): Promise<void> => {
        if (!categories?.paymentMethod || !categories?.vehicleType || !rate) {
            // Error
            notification['error']({
                message: 'Fehler',
                description:
                    'Es ist ein Fehler beim Versuch des Sendens aufgetreten. Bitte versuchen Sie es später erneut.',
            });
            return;
        }

        const dto: SubmitDataRequestDto = {
            companyName: values.company,
            location: values.place,
            paymentMethod: categories.paymentMethod,
            rateId: rate.rateId,
            vehicleCategory: categories.vehicleType,
            privacyPolicy: values.privacyNoticeAcceepted,
            akb: values.brokerContractAccepted,
            ...values,
        };

        try {
            setIbanError(undefined);
            const response = await axios.post<SubmitDataResponseDto>('submit', dto);
            setProcessNumber(response.data.processNumber);
        } catch (error: unknown) {
            setProcessNumber(undefined);
            const axiosError = error as AxiosError;
            console.error(axiosError);
            if (axiosError.response?.status === 400) {
                if (axiosError.response?.data === 'ibanNotValid') {
                    setIbanError('Die eingegebene IBAN ist ungültig.');
                } else {
                    notification['error']({
                        message: 'Fehler in den eingegebenen Daten',
                        description:
                            'Die eingegebenen Daten weisen einen Fehler auf. Bitte überprüfen Sie Ihre Eingaben.',
                    });
                }
            } else {
                notification['error']({
                    message: 'Fehler',
                    description:
                        'Es ist ein Fehler beim Versuch des Sendens aufgetreten. Bitte versuchen Sie es später erneut.',
                });
            }
        }
    };

    const onReturnToChoice = (): void => {
        setRate(null);
    };

    const onStartAnew = (): void => {
        setProcessNumber(undefined);
        setRate(null);
        setCategories({});
    };

    useEffect(() => {
        const fetchCategories = async (): Promise<void> => {
            if (!user) {
                setPaymentMethods([]);
                setVehicleTypes([]);
                return;
            }

            try {
                const response = await axios.get<CategoriesDto>('categories');
                setPaymentMethods(
                    response.data.paymentMethods.map((item) => {
                        return { label: item, value: item } as DefaultOptionType;
                    })
                );
                setVehicleTypes(
                    response.data.vehicleCategories.map((item) => {
                        return { label: item, value: item } as DefaultOptionType;
                    })
                );
            } catch (error: unknown) {
                setPaymentMethods([]);
                setVehicleTypes([]);
                notification['error']({
                    message: 'Fehler',
                    description:
                        'Der Server konnte nicht erreicht werden. Bitte versuchen Sie es später erneut.',
                });
            }
        };

        fetchCategories();
    }, [axios, user]);

    useEffect(() => {
        const fetchVehicleSpecifications = async (): Promise<void> => {
            if (!categories?.vehicleType) {
                return;
            }

            try {
                const response = await axios.get<VehicleSpecificationsDto>(
                    'api/categories/vehicleSpecifications',
                    {
                        params: {
                            vehicleCategory: categories.vehicleType,
                        },
                    }
                );
                const newPowerCategories = response.data.powerCategories.map((item) => {
                    return { label: item, value: item } as DefaultOptionType;
                });
                setPowerCategories(newPowerCategories);

                const newPriceCategories = response.data.priceCategories.map((item) => {
                    return { label: item, value: item } as DefaultOptionType;
                });
                setPriceCategories(newPriceCategories);

                setCategories({
                    ...categories,
                    powerCategory:
                        newPowerCategories.length === 1
                            ? newPowerCategories[0].value?.toString()
                            : undefined,
                    priceCategory:
                        newPriceCategories.length === 1
                            ? newPriceCategories[0].value?.toString()
                            : undefined,
                });
            } catch (error: unknown) {
                console.error(error);
                notification['error']({
                    message: 'Fehler',
                    description:
                        'Der Server konnte nicht erreicht werden. Bitte versuchen Sie es später erneut.',
                });
            }
        };

        fetchVehicleSpecifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [axios, categories?.vehicleType]);

    useEffect(() => {
        const fetchRates = async (): Promise<void> => {
            if (
                !categories?.powerCategory ||
                !categories.priceCategory ||
                !categories.vehicleType ||
                !categories.paymentMethod
            ) {
                setRates([]);
                return;
            }

            try {
                const response = await axios.get<GetRatesResponseDto>('rates', {
                    params: {
                        paymentMethod: categories.paymentMethod,
                        vehicleCategory: categories.vehicleType,
                        powerCategory: categories.powerCategory,
                        priceCategory: categories.priceCategory,
                    },
                });
                setRates(response.data.rates);
            } catch (error: unknown) {
                setRates([]);
                const axiosError = error as AxiosError;
                console.error(axiosError);
                if (axiosError.response?.status === 400) {
                    notification['error']({
                        message: 'Fehler in den eingegebenen Daten',
                        description:
                            'Die eingegebenen Daten weisen einen Fehler auf. Bitte überprüfen Sie Ihre Eingaben.',
                    });
                } else {
                    notification['error']({
                        message: 'Fehler',
                        description:
                            'Es ist ein Fehler am Server aufgetreten. Bitte versuchen Sie es später erneut.',
                    });
                }
            }
        };

        fetchRates();
    }, [axios, categories]);

    useEffect(() => {
        setSelectedRateId(undefined);
    }, [rates]);

    const showLogin = isAutoLoginCompleted && !user;
    const showContractChoice = isAutoLoginCompleted && user && !rate && !processNumber;
    const showDataGathering = isAutoLoginCompleted && user && rate && !processNumber;
    const showSubmitted = isAutoLoginCompleted && user && rate && processNumber;

    return (
        <div className='main-page-container'>
            <div className='main-page-content-container'>
                {!isAutoLoginCompleted && (
                    <div className='main-page-loading-container'>
                        <LoadingOutlined className='main-page-loading' />
                    </div>
                )}
                {showLogin && <LoginView />}
                {showContractChoice && (
                    <ContractChoiceView
                        onPaymentMethodChange={(method): void => {
                            setCategories({
                                ...categories,
                                paymentMethod: method,
                            });
                        }}
                        onRateChange={setRate}
                        onVehicleTypeChange={(type): void => {
                            setCategories({
                                ...categories,
                                vehicleType: type,
                                powerCategory: undefined,
                                priceCategory: undefined,
                            });
                            setPowerCategories([]);
                            setPriceCategories([]);
                        }}
                        paymentMethods={paymentMethods}
                        rates={rates}
                        vehicleTypes={vehicleTypes}
                        onPowerCategoryChange={(category): void => {
                            setCategories({
                                ...categories,
                                powerCategory: category,
                            });
                        }}
                        onPriceCategoryChange={(category): void => {
                            setCategories({
                                ...categories,
                                priceCategory: category,
                            });
                        }}
                        powerCategories={powerCategories}
                        priceCategories={priceCategories}
                        selectedCategories={categories}
                        selectedRateId={selectedRateId}
                        onSelectedRateIdChange={setSelectedRateId}
                    />
                )}
                {showDataGathering && (
                    <DataGathering
                        rate={rate}
                        paymentMethod={categories?.paymentMethod}
                        vehicleType={categories?.vehicleType}
                        onFormFinish={onSubmit}
                        onGoBack={onReturnToChoice}
                        ibanError={ibanError}
                    />
                )}
                {showSubmitted && (
                    <SubmittedView
                        processNumber={processNumber}
                        onReturnToBeginning={onStartAnew}
                    />
                )}
            </div>
        </div>
    );
};
